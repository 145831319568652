import React, { Component } from "react";
import { WindowOpener } from "../../lib/LibWindowOpener";

const ListLiveCasino = [
  {
    id: 10101,
    image: "sa-bar.jpg",
    title: "Baccarat",
    description: "",
  },
  {
    id: 10102,
    image: "sa-dt.jpg",
    title: "Dargon Tiger",
    description: "",
  },
  {
    id: 10103,
    image: "sa-sicbo.jpg",
    title: "Sic-Bo",
    description: "",
  },
];

class GameListLiveCasino extends Component {
  render() {
    const { column } = this.props;

    return (
      <React.Fragment>
        <h2>SA GAME</h2>
        <div className="row">
          {ListLiveCasino.map((value, i) => (
            <div className={`${column}`} key={i}>
              <WindowOpener
                url={`/play/${value.id}`}
                name="GAME"
                opts="width=1140,height=700, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no"
              >
                <div
                  className="service service__style--2"
                  style={{ padding: 0 }}
                >
                  <img src={`/assets/images/game/live/${value.image}`} />
                  <div className="content px-4 mt-4">
                    <h3 className="title">{value.title}</h3>
                  </div>
                </div>
              </WindowOpener>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default GameListLiveCasino;
