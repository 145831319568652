import React, { useState, useEffect } from "react";
import queryString from "query-string";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../components/common/Helmet";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import GameListDashboard from "../components/games/GameListDashboard";
import { ApiTransCheck } from "../modules/api/GameApi";
const PageDashboard = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const checkTransaction = async () => {
      let params = queryString.parse(props.location.search);
      console.log("dashboard props", props);
      console.log("dashboard params", params);
      if (params["transId"] !== "" && params["transId"] !== undefined) {
        let response = await ApiTransCheck(params["transId"]);
        if (response) {
          let apiReturn = response.data.data;
          console.log(apiReturn);
          if (apiReturn !== null && apiReturn[0] !== null) {
            if (apiReturn[0].TCC_STATUS === "01") {
              localStorage.setItem("transId", params["transId"]);
              // window.location.replace("/dashboard");
            } else {
              window.location.replace(apiReturn.EXPIRED_REDIRECT_URL);
            }
          } else {
            // setIsChecked(false);
            window.location.replace("/error/000");
          }
        }
      }
      if (localStorage.getItem("transId")) {
        setIsLoaded(true);
      }
    };
    checkTransaction();
  }, []);
  useEffect(() => {}, [isLoaded]);
  return (
    <>
      {isLoaded && (
        <>
          <div className="active-dark">
            <Helmet pageTitle="หน้าหลัก" />
            <Header homeLink="/" logo="symbol-dark" color="color-black" />

            {/* End Service Area  */}
            <div id="Home" className="fix">
              <div className="portfolio-area ptb--120">
                <div className="portfolio-sacousel-inner mb--55 mt--60">
                  <GameListDashboard />
                </div>
              </div>
            </div>
            <Footer />
            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}
          </div>
        </>
      )}
    </>
  );
};

export default PageDashboard;
