import React, { Component } from "react";
import { WindowOpener } from "../../lib/LibWindowOpener";

const ListSlot = [
  {
    id: 10201,
    image: "slot_202005251708313131.png",
    title: "Ocean Lord",
    description: "",
  },
  {
    id: 10202,
    image: "slot_202009081502131313.png",
    title: "Coin Rat",
    description: "",
  },
  {
    id: 10203,
    image: "slot_202009081503181818.png",
    title: "Chef Lady",
    description: "",
  },
  {
    id: 10201,
    image: "slot_202009081459121212.png",
    title: "Coin Cat",
    description: "",
  },
  {
    id: 10202,
    image: "slot_202005251709313131.png",
    title: "Let's Shoot",
    description: "",
  },
  {
    id: 10203,
    image: "slot_202005251724424242.png",
    title: "777",
    description: "",
  },
];

class GameListSlot extends Component {
  render() {
    const { column } = this.props;

    return (
      <React.Fragment>
        <h2>SIMPLY PLAY</h2>
        <div className="row">
          {ListSlot.map((value, i) => (
            <div className={`${column}`} key={i}>
              <WindowOpener
                url={`/play/${value.id}`}
                name="GAME"
                opts="width=1140,height=700, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no"
              >
                <div
                  className="service service__style--2"
                  style={{ padding: 0 }}
                >
                  <img src={`/assets/images/game/slot/${value.image}`} />
                  <div className="content px-4 mt-4">
                    <h3 className="title">{value.title}</h3>
                  </div>
                </div>
              </WindowOpener>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default GameListSlot;
