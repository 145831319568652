import axios from "axios";
import AppConfig from "../../config/AppConfig";

export function ApiTransCheck(transId) {
  return axios.get(AppConfig.PathApi, {
    params: { method: "transCheck", transId },
  });
}
export function ApiPlayGame(gameId, transId) {
  return axios.get(AppConfig.PathApi, {
    params: { method: "playGame", transId, gameId },
  });
}
export function ApiKickAll(transId) {
  return axios.get(AppConfig.PathApi, {
    params: { method: "kickAll", transId },
  });
}
