import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

const PageErrorAccessDenide = () => {
  return (
    <>
      {/* Start Page Error  */}
      <div className="error-page-inner ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h1 className="title theme-gradient">101!</h1>
                <h3 className="sub-title">พบข้อผิดพลาด</h3>
                <span>
                  พบข้อผิดพลาดในการเข้าถึงข้อมูล กรุณากลับไปที่หน้าล็อกอิน
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Error  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  );
};
export default PageErrorAccessDenide;
