import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../components/common/Helmet";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

const PageGameCard = () => {
  return (
    <div className="active-dark">
      <Helmet pageTitle="เกมส์ไพ่" />
      <Header homeLink="/" logo="symbol-dark" color="color-black" />

      {/* Start Service Area  */}
      <div id="GameCard" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
          <div className="container  mt--60 ">
            <div className="row creative-service">
              <div className="col-lg-12"></div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      <Footer />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PageGameCard;
