import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PageErrorAccessDenide from "./pages/PageErrorAccessDenide";
import PageDashboard from "./pages/PageDashboard";
import PageErrorNotFound from "./pages/PageErrorNotFound";
import PageGameLive from "./pages/PageGameLive";
import PageGameSlot from "./pages/PageGameSlot";
import PageGameCard from "./pages/PageGameCard";
import PagePlay from "./pages/PagePlay";

const App = (props) => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <PageDashboard {...props} />} />
      <Route
        path="/dashboard"
        render={(props) => <PageDashboard {...props} />}
      />
      <Route path="/g/live" component={PageGameLive} />
      <Route path="/g/slot" component={PageGameSlot} />
      <Route path="/g/card" component={PageGameCard} />
      <Route exact path="/play/:id" component={PagePlay} />
      <Route path="/error/000" component={PageErrorAccessDenide} />
      <Route path="/error/404" component={PageErrorNotFound} />
      {<Redirect to="/error/404" />}
    </Switch>
  );
};

export default App;
