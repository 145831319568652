import React, { Component } from "react";
const ListDashboard = [
  {
    image: "1",
    category: "บาคาร่า เสือมังกร รูเล็ต มีครบจบในเว็บเดียว",
    title: "คาสิโนสด",
    path: "/g/live",
  },
  {
    image: "2",
    category: "มีให้เลือกมากกว่า 300+ เกมส์",
    title: "เกมส์สล็อต",
    path: "/g/slot",
  },
  {
    image: "3",
    category: "มีหมดทั้งน้ำเต้าปูปลา ไฮโล ป๊อกเด้ง และอีกเพียบ",
    title: "เกมส์ไพ่",
    path: "/g/card",
  },
];

class GameListDashboard extends Component {
  render() {
    let title = "NEVER SAY BET",
      description =
        "ผู้ให้บริการเปิดรับวางเดิมพันในระบบออนไลน์แบบครบวงจร เต็มรูปแบบกับความสนุกสนานไร้ขีดจำกัด มั่นคง เว็บตรง ไม่ผ่านเอเย่นต์ ปลอดภัย 100%";
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>{title}</h2>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="rn-blog-area ptb--120 bg_color--1 mt--40">
            <div className="container">
              <div className="row">
                {ListDashboard.map((value, i) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <div className="blog blog-style--1">
                      <div className="thumbnail">
                        <a href={value.path}>
                          <img
                            className="w-100"
                            src={`/assets/images/game/main-${value.image}.jpg`}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="content">
                        <p className="blogtype">{value.category}</p>
                        <h4 className="title">
                          <a href={value.path}>{value.title}</a>
                        </h4>
                        <div className="blog-btn">
                          <a className="rn-btn text-white" href={value.path}>
                            เข้าเส่นเกมส์
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default GameListDashboard;
