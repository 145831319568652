import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../components/common/Helmet";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import ProviderListSIMPLEPLAY from "../components/games/GameListSlot";
const PageGameSlot = () => {
  return (
    <div className="active-dark">
      <Helmet pageTitle="เกมส์สล๊อต" />
      <Header homeLink="/" logo="symbol-dark" color="color-black" />

      {/* Start Service Area  */}
      <div id="GameSlot" className="fix">
        <div className="service-area creative-service-wrapper ptb--120">
          <div className="container  mt--60 ">
            <div className="row creative-service">
              <div className="col-lg-12">
                <ProviderListSIMPLEPLAY
                  item="6"
                  column="col-lg-2 col-md-3 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      <Footer />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PageGameSlot;
