import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { ApiPlayGame, ApiKickAll } from "../modules/api/GameApi";
const PagePlay = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  let params = useParams();
  // console.log("params=", params);
  useEffect(() => {
    let localTransId = localStorage.getItem("transId");
    // console.log(localTransId);
    // console.log(params.id);
    ApiKickAll(localTransId).then(({ data }) => {
      ApiPlayGame(params.id, localTransId).then(({ data }) => {
        // window.alert(JSON.stringify(data.data.data))
        window.location.replace(data.data.data.urlGame);
      });
    });
  }, [isLoaded]);
  return (
    <div>
      <div className="pt--120 text-white">
        <center>
          <ReactLoading
            type="bars"
            color="#f9004d"
            height={"5%"}
            width={"5%"}
          />
          <br />
        </center>
      </div>
    </div>
  );
};

export default PagePlay;
